/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable no-undef */
import { takeEvery, put, call, takeLatest, delay } from 'redux-saga/effects';
import { safeDeepGet } from 'iotera-base/utility/json';
import { getProjectHandle } from "Apps/device";
// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from './actionTypes';
import { loginSuccess, logoutUserSuccess, apiError } from './actions';
//Include Both Helper File with needed methods
import { getInstance } from '../../../firebase/helper';
import { postSocialLogin } from '../../../helpers/fakebackend_helper';
import { loginFirebase } from 'Apps/device';
import Result from 'iotera-base/result';
import { getAuth, signInWithCustomToken, signOut } from 'firebase/auth';
const firebaseInstance = getInstance();
const MULTITENANCY = JSON.parse(
  localStorage.getItem('multitenancy') || 'false'
);
const APPLICATION_ID = localStorage.getItem('application_id');
const auth = getAuth();
function* loginUser({ payload: { user, history } }) {
  try {
    let email = user.email.toLowerCase();
    if (MULTITENANCY) {
      var email_arr = email.split('@');
      email = email_arr[0] + '+' + APPLICATION_ID + '@' + email_arr[1];
    }
    const body = {
      email: user.email.toLowerCase(),
      password: user.password,
      vm_code: APPLICATION_ID.substring(APPLICATION_ID.length - 4),
    };
    const responseAP = yield call(loginFirebase, body);
    const code = safeDeepGet(responseAP, 'code', Result.UNKNOWN_ERROR);
    // const response = yield call(
    //   firebaseInstance.loginUser,
    //   email,
    //   user.password
    // );
    if (code == Result.HTTP.OK) {
      const status_body = safeDeepGet(responseAP, ['body', 'status_body'], '');
      const responseBody = safeDeepGet(
        responseAP,
        ['body', 'body'],
        safeDeepGet(responseAP, 'body', {})
      );
      const response = { ...responseBody, ...status_body };
      const authToken = safeDeepGet(responseAP, ['body', 'token'], null);
      if (authToken) {
        yield call(signInWithCustomToken, auth, authToken);
        var data = yield call(
          firebaseInstance.account.getRole,
          user.email.toLowerCase()
        );
        if (data.code == -1) {
          email = email.charAt(0).toUpperCase() + email.slice(1);
          data = yield call(firebaseInstance.account.getRole, email);
        }
        const phoneNumber = safeDeepGet(data, ['body', 'phone'], '');
        if (phoneNumber != '') {
          localStorage.setItem('phoneNumber', phoneNumber);
        }

        const name = safeDeepGet(data, ['body', 'name'], '');
        if (name != '') {
          localStorage.setItem('name', name);
        }
        const baseUrl = window.location.href.includes('localhost') ?"https://demo.smartvending.cloud/" : window.location.href ;
        const split_baseUrl = baseUrl.split('//');
        const url_sub = split_baseUrl[1];
        const split_url_sub = url_sub.split('.');
        const project = split_url_sub[0];
        const resultww = yield call(getProjectHandle);
        const body = safeDeepGet(resultww, ['body', project], {});
        const isMultiBalance = safeDeepGet(body, 'isMultiBalance', false);
        const isLandlordShare = safeDeepGet(body, 'shareLandlord', false);
        const typeCMS = safeDeepGet(body, 'type', false);
        if(typeCMS) {
          localStorage.setItem('type', typeCMS)
        }
        localStorage.setItem('isLandlordShare', isLandlordShare)
        if (
          JSON.parse(localStorage.getItem('isMultiBalance')) != isMultiBalance
        ) {
          localStorage.setItem('isMultiBalance', isMultiBalance);
        }
        const role = safeDeepGet(data, ['body', 'role'], 'super_admin');
        localStorage.setItem('role', JSON.stringify(role));
        localStorage.setItem('email', user.email);
        var access = yield call(firebaseInstance.role.getAccess, role);
        const allBp = safeDeepGet(access, ['body', 'allBp'], 'false');
        const permision = safeDeepGet(access, ['body', 'permision'], 'false');
        const accessBP = safeDeepGet(access, ['body', 'businessPoint'], []);
        const accessNav = safeDeepGet(access, ['body', 'navigation'], []);
        const tag = safeDeepGet(access, ['body', 'tag'], '');
        if (tag != '') {
          localStorage.setItem('tag', tag);
        }
        if (allBp == 'true') {
          localStorage.setItem('allBp', true);
        } else {
          localStorage.setItem('allBp', false);
        }
        if (permision == 'true') {
          localStorage.setItem('permision', true);
        } else {
          localStorage.setItem('permision', false);
        }
        localStorage.setItem('accessBP', JSON.stringify(accessBP));
        localStorage.setItem('accessNav', JSON.stringify(accessNav));

        localStorage.setItem('authUser', JSON.stringify(response));
        yield delay(500);
        // yield put(loginSuccess(response));

        history.push('/');
        location.reload();
      } else {
        const error = safeDeepGet(
          responseAP,
          ['body', 'message'],
          'Failed to Get Account'
        );
        yield put(apiError(error));
      }
    } else {
      const error = safeDeepGet(
        responseAP,
        ['body', 'message'],
        'Username atau password salah, atau account tidak Terdaftar'
      );
      yield put(apiError(error));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

// function* loginUser({ payload: { user, history } }) {
//   try {
//     let email = user.email.toLowerCase();
//     if(MULTITENANCY){
//       var email_arr = email.split('@')
//       email = email_arr[0]+"+"+APPLICATION_ID+'@'+email_arr[1]
//     }
//     var wsResult = yield call(
//       login,
//       email,
//       user.password,
//       APPLICATION_ID,
//       MULTITENANCY
//     );
//     var wsCode = safeGet(wsResult, 'code');
//     if (wsCode == Result.HTTP.OK) {
//       let response = safeGet(wsResult, ['body', 'body'], {});
//       response = yield call(
//         firebaseInstance.loginUser,
//         user.email,
//         user.password
//       );
//       const accessNav = safeDeepGet(wsResult, ['body', 'permision'], {});
//       localStorage.setItem('authUser', JSON.stringify(response));

//       const role = safeDeepGet(wsResult, ['body', 'role'], 'super_admin');
//       localStorage.setItem('role', JSON.stringify(role));
//       var access = yield call(firebaseInstance.role.getAccess, role);
//       const allBp = safeDeepGet(access, ['body', 'allBp'], 'false');
//       const permision = safeDeepGet(access, ['body', 'permision'], 'false');
//       const accessBP = safeDeepGet(access, ['body', 'businessPoint'], {});
//       if (allBp == 'true') {
//         localStorage.setItem('allBp', true);
//       } else {
//         localStorage.setItem('allBp', false);
//       }
//       if (permision == 'true') {
//         localStorage.setItem('permision', true);
//       } else {
//         localStorage.setItem('permision', false);
//       }
//       localStorage.setItem('accessBP', JSON.stringify(accessBP));
//       localStorage.setItem('accessNav', JSON.stringify(accessNav));
//       history.push('/dashboard');
//       location.reload();
//       yield put(loginSuccess(response));
//     } else {
//       yield put(
//         apiError(
//           'Maaf, Anda tidak memiliki izin. Silakan hubungi administrator atau pemilik aplikasi untuk mendapatkan akses yang sesuai.'
//         )
//       );
//     }
//   } catch (error) {
//     yield put(apiError(error));
//   }
// }
function* logoutUser({ payload: { history } }) {
  try {
    signOut(auth)
      .then(() => {
        localStorage.removeItem('authUser');
        localStorage.removeItem('role');
        localStorage.removeItem('email');
        localStorage.removeItem('allBp');
        localStorage.removeItem('permision');
        localStorage.removeItem('accessBP');
        localStorage.removeItem('accessNav');
        localStorage.removeItem('phoneNumber');
        localStorage.removeItem('tag');
        history.push('/login');
        location.reload();
      })
      .catch(error => {
        console.log(error);
      });
    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      const response = yield call(firebaseInstance.logout);
      yield put(logoutUserSuccess(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      const response = yield call(firebaseInstance.socialLoginUser, data, type);
      localStorage.setItem('authUser', JSON.stringify(response));
      yield put(loginSuccess(response));
    } else {
      const response = yield call(postSocialLogin, data);
      localStorage.setItem('authUser', JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history.push('/dashboard');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
