/* eslint-disable semi */
/* eslint-disable no-unused-vars */
import firebase from 'firebase/compat/app';
import { safeDeepGet } from 'iotera-base/utility/json';
import Base from './base';

export default class BusinessPoint extends Base {
  constructor(firebase, applicationId) {
    super(firebase, applicationId, 'role');
  }
  update = (id, payload) => {
    return new Promise((resolve, reject) => {
      const updatedPayload = {};
      for (const key in payload) {
        if (
          key === 'id' ||
          key === 'name' ||
          key === 'tag' ||
          key === 'useTag' ||
          key === 'allBp'
        ) {
          updatedPayload[key] = payload[key];
        }
      }
      updatedPayload['businessPoint'] = payload['bpMap'];
      updatedPayload['navigation'] = payload['navMap'];
      updatedPayload['permision'] = 'true';

      this.collection
        .doc(id)
        .update(updatedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };
  updateBp = (id, payload) => {
    return new Promise((resolve, reject) => {
      const updatedPayload = {};
      updatedPayload['businessPoint'] = payload;
      this.collection
        .doc(id)
        .update(updatedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };
  list = () => {
    return new Promise((resolve, reject) => {
      const list = {};
      this.collection.get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            list[doc.id] = doc.data();
          });
          resolve({ code: 0, body: list });
        },
        error => {
          console.log(error);
          resolve({ code: -1, body: error });
        }
      );
    });
  };
  read = id => {
    return new Promise((resolve, reject) => {
      let map = {};
      this.collection
        .where('id', '==', id)
        .get()
        .then(
          querySnapshot => {
            let code = -1;
            if (querySnapshot.size > 0) {
              code = 0;
              map = { ...querySnapshot.docs[0].data() };
            }
            resolve({ code: code, body: map });
          },
          error => {
            resolve({ code: -1, body: map });
          }
        );
    });
  };
  getAccess = id => {
    return new Promise((resolve, reject) => {
      let map = {};
      this.collection
        .where('id', '==', id)
        .get()
        .then(
          querySnapshot => {
            let code = -1;
            if (querySnapshot.size > 0) {
              code = 0;
              map = { ...querySnapshot.docs[0].data() };
            }
            resolve({ code: code, body: map });
          },
          error => {
            console.log(error);
            resolve({ code: -1, body: error });
          }
        );
    });
  };
  delete_ = uid => {
    return new Promise((resolve, reject) => {
      this.collection
        .doc(uid)
        .delete()
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };
  create = payload => {
    return new Promise((resolve, reject) => {
      const insertedPayload = {};
      for (const key in payload) {
        if (
          key === 'id' ||
          key === 'name' ||
          key === 'tag' ||
          key === 'useTag' ||
          key === 'allBp'
        ) {
          insertedPayload[key] = payload[key];
        }
      }
      insertedPayload['businessPoint'] = payload['bpMap'];
      insertedPayload['navigation'] = payload['navMap'];
      insertedPayload['permision'] = 'true';

      const id = payload['id'];
      this.collection
        .doc(id.toLowerCase())
        .set(insertedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };
}
